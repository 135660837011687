import { FC, useContext } from "react";
import cn from "classnames";
import TableCell from "./TableCell";
import { TableContext } from "./TableContext";

interface iProps {
    index: number;
    style: React.CSSProperties;
    data?: {
        styles: React.CSSProperties;
    };
}

const TableRow: FC<iProps> = ({ index, style, data }) => {
    const { config } = useContext(TableContext);

    const classNames = cn(
        "TableNew__row",
        index % 2 ? "is-odd" : "is-even"
    );

    return (
        <div
            className={classNames}
            style={{
                ...style,
                ...(data ? data.styles : {}),
            }}
            data-testid={`tableRow${index}`}
        >
            {config.map((item, colIndex) => {
                const { Value, ...column } = item;
                return (
                    <TableCell
                        key={colIndex}
                        columnData={{ ...column, Value }}
                        props={{ head: false }}
                        testId={`tableCell${index}_${colIndex}`}
                    >
                        <Value index={index} />
                    </TableCell>
                );
            })}
        </div>
    );
};

export default TableRow;
